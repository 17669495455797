body {
  background-color: rgba(241, 236, 236, 0);
}

.container {
  background-color: #ffffff;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  padding: 30px 0;
  width: 70%;
  font-family: "Times New Roman", Times, serif;
  font-size: 25px;
  font-weight: 500;
  border-radius: 7px;
  color: rgb(17, 16, 16);
  display: grid;
  box-shadow: 14px 10px 15px 10px rgba(0, 0, 0, 0.8);
  justify-items: center;
}

span {
  margin-top: 2%;
  display: inline-block;
  text-align: center;
  margin: 20px 0;
  background-color: rgb(31, 164, 247);
  box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  padding: 10px 30px;
  font-size: 20px;
  letter-spacing: 0.8px;
  border-radius: 8px;
}
